.Form {
  display: flex;
  flex-direction: column;
}

.TripInput {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 25px; */
  border-radius: 10px;
  border-radius: 4.8px;
  padding: 10px;
  gap: 10px;
}

.FlexBox {
  margin-top: 25px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.DestinationInput {
  flex: 1;
  border: 2px solid #3f4341d7;
  border-radius: 4.8px;
  background-color: rgb(40, 42, 41);
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding: 10px;
}
.DestinationInput::placeholder {
  color: #c0c2cb;
}
.DatePickerContainer {
  padding: 10px;
  background-color: rgb(40, 42, 41);
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4.8px;
  border: 2px solid #3f4341d7;
  gap: 10px;
}

.DestinationFacts {
  padding: 10px;
  background-color: rgb(40, 42, 41);
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4.8px;
  border: 2px solid #3f4341d7;
  gap: 10px;
  min-width: 284px;
}

.LoadingSpinnerContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingSpinner {
  height: 100%;
  width: 100%;
}

.DatePickerText {
  /* height: 60px; */
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.DateRangeInputBox {
  max-width: 250px;
  background-color: white;
}
.DateRangeInput {
  max-width: 400px;
  background-color: white;
}

.TripInfoContainer {
  padding: 5px 0 0 10px;
}

.TripInfoText {
  display: flex;
  color: white;
  flex-direction: column;
  /* padding-left: 10px; */
}
.Descriptor {
  border-radius: 4.8px;
  font-size: 10px;
  color: #adb5bd;
}
.tripInfo {
  padding-left: 12px;
}
