.PackingGuideContainer {
  display: flex;
  flex-direction: column;
}
.PackingGuide {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
}

.PackContainer {
  overflow: hidden;
  border-radius: 4.8px;
  border: 2px #3f4341d7 solid;
  background-color: rgb(40, 42, 41);
  text-decoration: none;
  margin: 0 10px;
  color: #fff;
  flex: 1;
  transition: 0.5s;
  padding: 20px;
  min-width: 214px;
}

.Clothing {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
}

.TripInfoText {
  color: #fff;
  display: flex;
  flex-direction: column;
  /* padding-left: 10px; */
}
.Descriptor {
  border-radius: 4.8px;
  font-size: 10px;
  color: #adb5bd;
}
.tripInfo {
  padding-left: 12px;
}

.ButtonContainer {
  display: flex;
}

.Button {
  cursor: pointer;
  margin: 10px;
  border: none;
  border-radius: 4.8px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2ab57d;
  color: #fff;
  padding: 7px 24px;
}
