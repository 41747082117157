.HamburgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.burger {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.SvgHolder {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}

.BurgerMenu {
  position: absolute;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100vw;
  top: 51px;
  right: 0;
  background-color: #2c302e;
  padding: 10px;
  z-index: 2;
}

.DisplayNone {
  display: none;
}

.Link {
  font-size: 14px;
  font-weight: 700;
  color: #a7a9b6;
  text-decoration: none;
}
