.DestinationImage {
  display: flex;
  overflow: hidden;
  height: 140px;
  border-radius: 9px;
  margin-bottom: 30px;
}

.Image {
  width: 100%;
  object-fit: cover;
}
