.PackingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 178px;
  border: 2px solid #3f4341d7;
  border-radius: 9px;
  padding: 8px;
  cursor: pointer;
  color: #adb5bd;
  height: 40px;
  transition: 0.5s;
}
.PackingItemActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  border: 1px solid black;
  border-radius: 9px;
  padding: 8px;
  color: #adb5bd;
  transition: 0.5s;
}

.PackingItem:hover {
  color: #fff;
}

.Label {
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100px;
}

.CustomInput {
  width: 100px;
}

.PlusMinusContainer {
  flex: 1;
  display: flex;
  gap: 5px;
}
.PlusMinusPLUS {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: #2ab57d;
  box-shadow: 0px 4px 6px -1px #3f4341, 0px 2px 4px -2px #3f4341;
  border-radius: 4.8px;
  color: #fff;
  height: 25px;
  width: 40px;
}
.PlusMinus {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: #2ab57d;
  box-shadow: 0px 4px 6px -1px #3f4341, 0px 2px 4px -2px #3f4341;
  border-radius: 4.8px;
  color: #fff;
  height: 25px;
  width: 40px;
}

.PlusNope {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: #b52a2a;
  box-shadow: 0px 4px 6px -1px #3f4341, 0px 2px 4px -2px #3f4341;
  border-radius: 4.8px;
  color: #fff;
  height: 25px;
  width: 50px;
}

.Value {
  font-weight: 700;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
