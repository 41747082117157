.PackingItem {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  border: 2px solid #3f4341d7;
  border-radius: 9px;
  padding: 8px;
}

.Label {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #adb5bd;
}
.PlusMinusContainer {
  flex: 1;
  display: flex;
  gap: 10px;
}
.PlusMinus {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: #5156be;
  box-shadow: 0px 4px 6px -1px #3f4341, 0px 2px 4px -2px #3f4341;
  border-radius: 4.8px;
  color: #fff;
  height: 25px;
  width: 50px;
}
.Value {
  font-weight: 700;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
