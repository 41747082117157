.Home {
  margin-top: 40px;
  display: flex;
  padding: 80px;
}

.Button {
  cursor: pointer;
  margin: 10px;
  border: none;
  border-radius: 4.8px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2ab57d;
  color: #fff;
  padding: 7px 24px;
}

.TripCardContainer {
  display: flex;
  gap: 10px;
  max-width: 1100px;
  flex-wrap: wrap;
}

@media screen and (max-width: 700px) {
  .Home {
    padding: 0 0 20px 0;
  }
  .TripCardContainer {
    justify-content: center;
  }
}

.LoadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.LoadingSpinner {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
}

.TripCard {
  position: relative;
  border-radius: 10px;
  border-radius: 4.8px;
  border: 3px #3f4341d7 solid;
  background-color: #2c302e;
  height: 300px;
  width: 200px;
  max-height: 300px;
  text-decoration: none;
  transition: 0.5s;
}

.PlanATrip {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.TripCard:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Link {
  color: #fff;
  text-decoration: none;
}

.CloseIcon {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 2px solid #8c8c8c94;
  background-color: #8c8c8c94;
  color: #fff;
  top: -10px;
  right: -10px;
  transition: 0.3s;
}
.CloseIcon:hover {
  background-color: #fff;
  color: #2c302e;
}

.ImageContainer {
  width: 100%;
  height: 130px;
  overflow: hidden;
}
.Image {
  /* display: none; */
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.TripInfoContainer {
  padding: 5px 0 0 10px;
}

.TripInfoText {
  display: flex;
  flex-direction: column;
  /* padding-left: 10px; */
}
.Descriptor {
  border-radius: 4.8px;
  font-size: 10px;
  color: #adb5bd;
}
.tripInfo {
  padding-left: 12px;
}
