.Preferences {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 80px;
}

.PreferencesContainer {
  display: flex;
  flex-direction: column;
  background-color: #2c302e;
  width: 528px;
  padding: 25px;
}
.PreferencesLabel {
  color: #d9dae0;
  font-weight: 600;
  font-size: 15px;
}
.PreferencesHelper {
  color: #d9dae0;
  font-size: 12px;
}
.Form {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 25px;
}

.InputContainer {
  display: flex;
  flex-direction: column;
}

.InputLabel {
  color: #adb5bd;
  font-size: 14px;
  font-weight: 600;
}

.CheckboxLabel {
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  color: #adb5bd;
  font-size: 14px;
  font-weight: 600;
}

.InputDiv {
  padding-top: 10px;
  display: flex;
}

.Input {
  height: 38px;
  padding: 2px 5px;
  border: 1px solid #3f4341;
  background-color: #363a389e;
  width: 225px;
  color: #adb5bd;
  font-size: 14px;
  border-radius: 10px;
}

.Checkboxes {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  border: 1px solid black;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid black;
  width: 100px;
  height: 75px;
}

.CheckboxInput {
  padding: 2px 5px;
  border: 1px solid #3f4341;
  background-color: #363a389e;
  color: #adb5bd;
  font-size: 14px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.Button {
  cursor: pointer;
  margin: 10px;
  border: none;
  border-radius: 4.8px;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2ab57d;
  color: #fff;
  padding: 7px 24px;
}
