.Trip {
  flex-direction: column;
  margin-top: 40px;
  display: flex;
  padding: 0 80px 80px 80px;
  flex: 1 1 930px;
  max-width: 1150px;
}
@media screen and (max-width: 705px) {
  .Trip {
    padding: 0;
  }
}

.TripInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  border-radius: 10px;
  border-radius: 4.8px;
  border: 2px #3f4341d7 solid;
  background-color: #2c302e;
  padding: 10px;
  gap: 10px;
}
